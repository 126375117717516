import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import type { ConfigContextValue, ProjectContextValue, VersionContextValue } from '@core/context';
import { ConfigContext, ProjectContext, VersionContext } from '@core/context';
import usePlanPermissions from '@core/hooks/usePlanPermissions';
import useProjectPlan from '@core/hooks/useProjectPlan';
import useUniqueId from '@core/hooks/useUniqueId';
import { useProjectStore, useSuperHubStore } from '@core/store';

import type { SuperHubRouteParams } from '@routes/SuperHub/types';

import DashEditor from '@ui/MarkdownEditor/DashEditor';
import { RHFGroup } from '@ui/RHF';

import { useSuperHubEditorFormContext, useEditorKeyContext, useEditorValueProxyContext } from '../../Context';

import ValidationError from './ValidationError';

/**
 * Renders a form field that registers the @ui/MarkdownEditor component
 * with the SuperHubEditorFormContext.
 */
function MarkdownEditor() {
  const uid = useUniqueId('SuperHubEditorForm');
  const { domainFull } = useContext(ConfigContext) as ConfigContextValue;
  const { version } = useContext(VersionContext) as VersionContextValue;
  const {
    project: { fullBaseUrl, variableDefaults, plan, planOverride, parent, subdomain },
  } = useContext(ProjectContext) as ProjectContextValue;
  const { section = '' } = useParams<SuperHubRouteParams>();
  const { isTrial } = useProjectPlan();
  const hasReusableContentPermissions = usePlanPermissions(planOverride || plan, 'reusableContent');
  const isReusableContentEnabled = isTrial || hasReusableContentPermissions;
  // Custom Blocks should not be available on any page except for the docs and reference sections
  const disableCustomBlocks = !['docs', 'reference'].includes(section);

  const { editorValueProxy, setEditorValueProxy } = useEditorValueProxyContext();

  const [glossaryTerms] = useProjectStore(s => [s.data.glossary]);
  const [customBlocks, updateCustomBlock, isRawMode, reenableSaving] = useSuperHubStore(s => [
    s.document.customBlocks,
    s.document.updateCustomBlock,
    s.editor.isRawMode,
    s.editor.reenableSaving,
  ]);

  const {
    control,
    formState: { defaultValues },
    setValue,
  } = useSuperHubEditorFormContext();
  const { editorKey } = useEditorKeyContext();

  /**
   * Holds reference to the previous editor key so we can know when a fresh
   * remounting and re-render of the MD editor is occurring.
   */
  const previousEditorKey = useRef(editorKey);
  useEffect(() => {
    previousEditorKey.current = editorKey;
  }, [editorKey]);

  /** For custom pages only, indicates whether HTML mode is enabled or not. */
  const isHtmlMode = useWatch({ control, name: 'content.type' }) === 'html';

  /**
   * Holds a snapshot of the seralized MD editor body when first switching to MD
   * mode. In case the editor contains invalid markup and crashes, we store its
   * body here so we can restore the `content.body` if needed.
   */
  const cachedEditorValueRef = useRef('');

  // When switching from MD to HTML/Raw mode, we have to update our form field's
  // value in an async way in order to preserve changes made in the editor body.
  // Otherwise, MD changes will get lost during the mode change. We must run
  // this update in a useMemo instead of a useEffect because the field must
  // update *before* the editor component is re-rendered.
  useMemo(() => {
    if (isRawMode || isHtmlMode) {
      // This should run only once when switching to Raw or HTML mode.
      const isEditorRemounting = previousEditorKey.current !== editorKey;

      // Only when the editor is *not* remounting should we use the current
      // editor value as a fallback for the raw mode content. Otherwise, we
      // could be falling back to MD content from another page that was last
      // loaded into the MD editor.
      const editorFallback = isEditorRemounting ? '' : editorValueProxy?.toString();
      setValue(
        'content.body',
        // Attempt to use the cached value first, then current editor value.
        // Otherwise fallback to the last "saved" state.
        cachedEditorValueRef.current || editorFallback || defaultValues?.content?.body || '',
      );
    } else {
      // This should run only once when switching to MDX mode. We cache the MD
      // editor's value in case the editor crashes and loses its current value.
      // If it does crash, then we can use this cached value to restore the
      // original `content.body` when switching modes.
      cachedEditorValueRef.current = editorValueProxy?.toString() || '';
    }
  }, [defaultValues?.content?.body, editorKey, editorValueProxy, isHtmlMode, isRawMode, setValue]);

  return (
    <>
      <ValidationError />
      <RHFGroup control={control} id={uid('content-body')} name="content.body">
        {({ field, fieldState }) => (
          <DashEditor
            // The key is used to force the MarkdownEditor to re-render when the
            // content changes. This is necessary because the MarkdownEditor
            // doesn't update its internal state when the `field.value` changes.
            key={editorKey}
            customBlocks={customBlocks}
            disallowCustomBlocks={disableCustomBlocks}
            // DashEditor uses `doc` prop differently depending on whether it's
            // in MD mode vs HTML/Raw mode. When in MD mode, the `doc` prop acts
            // as the initial value only when rendered for the first time,
            // triggered by a `key` change. When in HTML/Raw mode, it acts as a
            // controlled input for the HTML or raw code editor.
            //
            // Even when used as an initial value, we need to pass the current
            // field value so the editor can re-initialize and retain the most
            // recent change when switching between HTML or raw mode.
            doc={{ value: field.value === editorKey ? '' : field.value || '' }}
            domainFull={domainFull}
            glossaryTerms={glossaryTerms}
            html={isHtmlMode ? field.value || '' : undefined}
            htmlMode={isHtmlMode}
            onChange={valueProxy => {
              if (valueProxy.dirty) reenableSaving();

              // Process change event only when in MD mode.
              if (isRawMode || isHtmlMode) return;

              // Process change event only when MD editor was updated.
              if (!valueProxy.dirty) return;

              // When MD editor is updated, clear the cached editor value that
              // was snapshotted to signify that it is now stale.
              cachedEditorValueRef.current = '';

              // For performance reasons we don't serialize the editor value
              // with `editor.toString()` on every change event, but we do want
              // to update the field's dirty state when the value changes. So we
              // call `field.onChange` with the `editorKey` uid to trigger the dirty
              // state change. Then when the form is submitted, if the field value
              // is still the `editorKey`, we know to serialize the editor value
              // with `editor.toString()`.
              if (!fieldState.isDirty) {
                field.onChange(editorKey);
              }
            }}
            onCustomBlockSave={({ data }) => {
              updateCustomBlock(data);
            }}
            onHtmlChange={html => {
              field.onChange(html);
            }}
            onInit={setEditorValueProxy}
            onRawChange={value => {
              field.onChange(value);
            }}
            parentSubdomain={parent?.subdomain}
            projectBaseUrl={fullBaseUrl}
            rawMode={isRawMode}
            reusableContentMode={isReusableContentEnabled ? 'default' : 'no-plan-access'}
            subdomain={subdomain}
            superhub
            useAPIv2
            useMDX
            useReusableContent={isReusableContentEnabled}
            variableDefaults={variableDefaults}
            version={version}
          />
        )}
      </RHFGroup>
    </>
  );
}

export default MarkdownEditor;
